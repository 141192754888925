


























































import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class AgentDetails extends Vue {
  loading: boolean = false;
  id!: any;
  agentData: any = null;
  pagerHeader: any = {
    title: "代理详细",
    desc: "代理详细",
    breadcrumb: ["代理管理", "代理详细"]
  };
  async readerAgentInfo() {
    this.loading = true;
    try {
      var res = await this.$ajax.get("/api/services/app/Agent/GetInfo", {
        params: {
          id: this.id
        }
      });
      this.agentData = res.data.result;
    } catch (error) {}
    this.loading = false;
  }
  created() {
    this.id = this.$route.query.id;
    this.readerAgentInfo();
  }
  activated() {
    if (this.id != this.$route.query.id) {
      this.id = this.$route.query.id;
      this.readerAgentInfo();
    }
  }
}
